import { Form, Formik, FormikActions, FormikProps, FormikErrors } from "formik";
import React from "react";
import { defaultErrorHandler } from "services/api/errors";
import * as yup from "yup";
import { ICompany, IUpdateCompanyCommand } from "services/api/types";
import { formatDate } from "utils/date";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import { Observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import TaxNumberTypeField from "./TaxNumberTypeField";
import CompanyNameField from "./CompanyNameField";
import WebsiteField from "./WebsiteField";
import EnrollDateField from "./EnrollDateField";
import IsActiveField from "./IsActiveField";
import IsVisibleToMembersField from "./IsVisibleToMembersField";
import TaxNumberField from "./TaxNumberField";
import SupplierTypeField from "./SupplierTypeField";
import MonthlyMinimumField from "./MonthlyMinimumField";
import enumerableStore from "stores/enumerable";
import MarketingInformationField from "./MarketingInformationField";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  website: yup.string().notRequired(),
  companyType: yup.string().required(),
  minimumMonthlyPurchase: yup.number(),
  marketingInformation: yup.string(),
  enrollDate: yup.date().required(),
  isActive: yup.boolean().required(),
  visibleTomembers: yup.boolean().notRequired(),
  taxNumber: yup.string().notRequired(),
  taxNumberType: yup.string().oneOf(["EIN", "SSN"]).notRequired(),
  primaryCategory: yup.string(),
});

type FormData = Partial<IUpdateCompanyCommand>;

type Props = {
  company: ICompany;
  handleCancel: () => void;
  handleUpdate: (command: IUpdateCompanyCommand) => void;
};

const AddSupplierForm = (props: Props) => {
  const { company } = props;

  const handleFormSubmit = async (
    values: FormData,
    actions: FormikActions<FormData>
  ) => {
    const command: IUpdateCompanyCommand = {
      id: props.company.id,
      name: values.name ?? "",
      website: values.website || undefined,
      minimumMonthlyPurchase: values.minimumMonthlyPurchase,
      enrollDate: formatDate(values.enrollDate ?? new Date()),
      companyType: values.companyType!,
      sponsorVisibleToMembers: values.sponsorVisibleToMembers,
      isActive: values.isActive!,
      taxNumber: values.taxNumber,
      taxNumberType: values.taxNumberType,
      marketingInformation: values.marketingInformation,
    };

    try {
      await props.handleUpdate(command);
    } catch (err) {
      defaultErrorHandler(err);
    }
    actions.setSubmitting(false);
  };

  React.useEffect(() => {
    enumerableStore.ensureTaxNumberTypesFetched();
    enumerableStore.ensureCategoriesFetched();
  });

  const initialValues: FormData = {
    name: company.name,
    website: company.website,
    companyType: company.companyType,
    minimumMonthlyPurchase: company.minimumMonthlyPurchase,
    sponsorVisibleToMembers: company.sponsorVisibleToMembers,
    enrollDate: formatDate(company.enrollDate),
    isActive: company.isActive,
    taxNumber: company.taxNumber,
    taxNumberType: company.taxNumberType,
    marketingInformation: company.marketingInformation ?? "",
  };

  const renderCompanyName = (formikProps: FormikProps<FormData>) => {
    return (
      <CompanyNameField
        handleChange={formikProps.handleChange}
        touched={formikProps.touched.name}
        error={formikProps.errors.name}
        value={formikProps.values.name ?? ""}
      />
    );
  };

  const renderWebsite = (formikProps: FormikProps<FormData>) => {
    return (
      <WebsiteField
        handleChange={formikProps.handleChange}
        touched={formikProps.touched.website}
        error={formikProps.errors.website}
        value={formikProps.values.website}
      />
    );
  };

  const renderMarketingInformation = (formikProps: FormikProps<FormData>) => {
    return (
      <MarketingInformationField
        handleChange={formikProps.handleChange}
        touched={formikProps.touched.marketingInformation}
        error={formikProps.errors.marketingInformation}
        value={formikProps.values.marketingInformation}
      />
    );
  };

  const renderTaxNumber = (formikProps: FormikProps<FormData>) => {
    return (
      <TaxNumberField
        error={formikProps.errors.taxNumber}
        handleChange={formikProps.handleChange}
        value={formikProps.values.taxNumber}
        touched={formikProps.touched.taxNumber}
        taxNumberType={formikProps.values.taxNumberType}
      />
    );
  };

  const renderSupplierType = (formikProps: FormikProps<FormData>) => {
    return (
      <SupplierTypeField
        touched={formikProps.touched.companyType}
        error={formikProps.errors.companyType}
        handleChange={formikProps.handleChange}
        value={formikProps.values.companyType}
      />
    );
  };

  const renderEnrollDate = (formikProps: FormikProps<FormData>) => {
    return (
      <EnrollDateField
        touched={formikProps.touched.enrollDate}
        error={formikProps.errors.enrollDate}
        handleChange={formikProps.handleChange}
        value={formatDate(formikProps.values.enrollDate ?? new Date())}
      />
    );
  };

  const renderIsActive = (formikProps: FormikProps<FormData>) => {
    return (
      <IsActiveField
        handleChange={(s) => {
          const isSponsor = formikProps.values.companyType === "Sponsor";
          if (isSponsor) {
            formikProps.setFieldValue(
              "sponsorVisibleToMembers",
              !formikProps.values.isActive
            );
          }
          formikProps.handleChange(s);
        }}
        value={formikProps.values.isActive ?? true}
      />
    );
  };

  const renderIsVisibleToMembers = (formikProps: FormikProps<FormData>) => {
    if (formikProps.values.companyType !== "Sponsor") return null;

    const value =
      formikProps.values.sponsorVisibleToMembers == null
        ? true
        : formikProps.values.sponsorVisibleToMembers;

    return (
      <IsVisibleToMembersField
        handleChange={formikProps.handleChange}
        value={value}
        disabled={!formikProps.values.isActive}
      />
    );
  };

  const renderTaxNumberType = (
    formikProps: FormikProps<FormData>,
    taxNumberTypes: string[]
  ) => {
    return (
      <TaxNumberTypeField
        touched={formikProps.touched.taxNumberType}
        error={formikProps.errors.taxNumberType}
        value={formikProps.values.taxNumberType}
        handleChange={(e) => {
          formikProps.setFieldValue("taxNumber", "");
          formikProps.handleChange(e);
        }}
        taxNumberTypes={taxNumberTypes}
      />
    );
  };

  const renderMonthlyMinimum = (formikProps: FormikProps<FormData>) => {
    return (
      <MonthlyMinimumField
        touched={formikProps.touched.minimumMonthlyPurchase}
        error={formikProps.errors.minimumMonthlyPurchase}
        value={formikProps.values.minimumMonthlyPurchase?.toString()}
        handleChange={formikProps.handleChange}
      />
    );
  };

  return (
    <Observer
      render={() => {
        const taxNumberTypes = enumerableStore.getEnum("taxNumberTypes");

        return (
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            validate={(values) => {
              const errors: FormikErrors<FormData> = {};

              if (values.taxNumber && !values.taxNumberType) {
                errors.taxNumberType = "This is required";
              }

              if (values.taxNumberType && !values.taxNumber) {
                errors.taxNumber = "This is required";
              }

              return errors;
            }}
            onSubmit={handleFormSubmit}
          >
            {(formikProps) => {
              return (
                <Form>
                  <ResponsiveModalShell
                    handleClose={props.handleCancel}
                    title="Update Supplier"
                    disableSaveButton={formikProps.isSubmitting}
                    handleSave={() => formikProps.submitForm()}
                  >
                    <Grid alignItems="center" container spacing={1}>
                      <Grid item xs={12}>
                        {renderSupplierType(formikProps)}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {renderCompanyName(formikProps)}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {renderWebsite(formikProps)}
                      </Grid>
                      <Grid item xs={12}>
                        {renderMarketingInformation(formikProps)}
                      </Grid>
                      <Grid item xs={12}>
                        {renderIsActive(formikProps)}
                        {renderIsVisibleToMembers(formikProps)}
                      </Grid>
                      <Grid item xs={6}>
                        {renderEnrollDate(formikProps)}
                      </Grid>
                      <Grid item xs={6}>
                        {renderMonthlyMinimum(formikProps)}
                      </Grid>
                      <div style={{ width: "100%" }} />
                      <Grid item xs={6}>
                        {renderTaxNumberType(formikProps, taxNumberTypes)}
                      </Grid>
                      <Grid item xs={6}>
                        {renderTaxNumber(formikProps)}
                      </Grid>
                    </Grid>
                  </ResponsiveModalShell>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    />
  );
};

export default AddSupplierForm;
