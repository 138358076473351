import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  value: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  disabled: boolean;
};

const VisibleToMembersField = (props: Props) => {
  return (
    <FormControlLabel
      label="Visible To Members"
      checked={props.value}
      disabled={props.disabled}
      control={
        <Checkbox
          checked={props.value}
          onChange={props.handleChange}
          name="sponsorVisibleToMembers"
        />
      }
    />
  );
};

export default VisibleToMembersField;
