import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import api from "services/api";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import clsx from "clsx";
import { useHistory } from "react-router";
import { paths } from "routes/Paths";
import red from "@material-ui/core/colors/red";

type Props = {
  handleClose: () => void;
  sponsorId: string;
  saveCallback?: () => void;
};

type SpreadsheetResult = "NotUploaded" | "Uploaded" | "InvalidUpload";

const NewMemberUploadModal = (props: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [spreadsheetResult, setSpreadsheetResult] =
    React.useState<SpreadsheetResult>("NotUploaded");

  const [isDownloading, setIsDownloading] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [memberUploadId, setMemberUploadId] = React.useState<
    string | undefined
  >();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      success: {
        color: "green",
        marginTop: theme.spacing(4),
      },
      link: {
        color: "blue",
        cursor: "pointer",
      },
      disabledLink: {
        color: "gray",
        textDecoration: "none",
        pointerEvents: "none",
      },
      note: {
        color: theme.palette.grey[500],
      },
      error: {
        color: theme.palette.error[500],
        marginTop: theme.spacing(2),
        transform: "translateY(20px)",
        whiteSpace: "pre-line",
        maxHeight: 300,
        overflowY: "scroll",
      },
      warning: {
        color: red[500],
      },
      errors: {
        "& textarea": {
          maxHeight: 200,
          fontFamily: "monospace",
          fontSize: "12pt",
          overflowY: "scroll !important",
        },
      },
    })
  );

  const classes = useStyles();
  const history = useHistory();

  const handleTemplateDownload = async () => {
    setIsDownloading(true);
    try {
      await api.admin.sponsors.downloadNewMemberTemplate();

      setIsDownloading(false);
    } catch (e) {
      setIsDownloading(false);
    }
  };

  const handleCancel = async () => {
    if (memberUploadId) {
      await api.admin.sponsors.deleteMemberUpload(memberUploadId);
    }

    props.handleClose();
  };

  const handleUploadClicked = async () => {
    await api.admin.sponsors.validateMemberUpload(memberUploadId!);

    props.handleClose();
    history.push(
      `${paths.app.admin.companyDetails}/${props.sponsorId}/member-uploads`
    );

    if (props.saveCallback) props.saveCallback();
  };

  const handleFileInputChanged = async () => {
    // Upload and create attachment right away...?
    const file = inputRef.current!.files![0];
    setIsUploading(true);

    try {
      const result = await api.admin.sponsors.uploadMemberListSpreadhseet(
        file,
        props.sponsorId,
        memberUploadId
      );

      setMemberUploadId(result.data.memberUploadId);
      setError("");
      setSpreadsheetResult("Uploaded");
    } catch (e) {
      if (typeof e === "string") {
        setError(e as string);
      }
      setSpreadsheetResult("InvalidUpload");
    } finally {
      inputRef.current!.value = "";
      setIsUploading(false);
    }
  };

  return (
    <ResponsiveModalShell
      disableSaveButton={spreadsheetResult !== "Uploaded"}
      handleClose={handleCancel}
      saveButtonCaption="OK"
      handleSave={handleUploadClicked}
      title="Upload Member List"
    >
      <div className={classes.root}>
        <Typography>
          <span
            className={clsx(classes.link, {
              [classes.disabledLink]: isDownloading,
            })}
            onClick={() => window.setTimeout(handleTemplateDownload, 100)}
          >
            Click here
          </span>
          , to download a template. Fill it out, then click the button below to
          select your spreadsheet.
        </Typography>
        <div>
          <input
            ref={inputRef}
            type="file"
            name="file"
            onChange={handleFileInputChanged}
          />
        </div>
        {isUploading && <Typography>Uploading...</Typography>}
        {spreadsheetResult === "Uploaded" && (
          <div>
            <Typography className={classes.success}>
              The system recognizes the spreadsheet you uploaded and is ready to
              start scanning it for data quality issues. Click 'OK' to continue.
            </Typography>
          </div>
        )}
        {spreadsheetResult === "InvalidUpload" && (
          <div>
            <Typography className={classes.error}>{error}</Typography>
          </div>
        )}
      </div>
    </ResponsiveModalShell>
  );
};

export default NewMemberUploadModal;
